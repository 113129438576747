import React, { useEffect, useState } from 'react';
import './Payment.scss';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPresentById, stillThinking } from '../../store/reducers/PresentationReducer';
import Loader from '../Loader/Loader';
import Icon from '../../Icon';
import toast, { Toaster } from 'react-hot-toast';

const Payment = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { presentation, loading } = useSelector(state => state.presentation);
	const [presentationInfo, setPresentationInfo] = useState(null);
	const { id, date, treatmentId } = useParams();

	const handleYes = () => {
		navigate(`/payment-form/${id}/${date}/${treatmentId}`);
	};

	const handleShare = () => {
		// toast
		// 	.promise(dispatch(stillThinking({ id: id })), {
		// 		loading: 'Loading',
		// 		success: 'Email send successfuly',
		// 		error: 'Error'
		// 	})
		navigate(`/thank-you/${id}/${date}/${treatmentId}`)
	};

	useEffect(() => {
		if (!presentation) {
			dispatch(getPresentById({ id, date, treatmentId }));
		}
	}, []);

	useEffect(() => {
		setPresentationInfo(presentation);
	}, [presentation]);

	if (loading) {
		return (
			<div className="payment-wrapper">
				<div className="payment-block">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<>
			<Toaster position="top-right" />
			<div className="payment-wrapper">
				{/* <div className={popup ? 'popup-question' : 'hide-popup'}>
					<div className="input-block">
						<div className="popup-close" onClick={() => setPopup(false)}>
							<Icon name="close" styles={{ fill: 'black', width: '20' }}></Icon>
						</div>
						<h5 className="popup-header">I'm still Thinking</h5>
						<p className="instruction">Please, enter the comma-separated list of emails</p>
						<p className="example">example1@email.com, example2@email.com, ...</p>
						<textarea
							type="text"
							placeholder={`example1@gmail.com, example2@gmail.com`}
							onChange={handleEmail}
						/>
						<button className="share-button" onClick={handleShare}>
							Share
						</button>
					</div>
				</div> */}
				<div className="payment-block">
					<img src={presentationInfo?.organization.logo || ''} alt="avatar" width={150} height={150} style={{borderRadius: '15px'}} />
					<div className="payment-block-text">
						<p className="payment-block-title">
							Here is your down payment: $
							{presentationInfo?.calculations?.payments?.bpSelectedDownpayment_calc?.toFixed(2)}
						</p>
						<p className="payment-block-details">
							Would you like to pay it now to avoid delays on the day of banding?
						</p>
					</div>
					<div className="payment-block-buttons">
						<button className="payment-block-yes-button" onClick={handleYes}>
							Yes
						</button>
						<button className="payment-block-thinking-button" onClick={handleShare}>
							I'm still thinking
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Payment;
