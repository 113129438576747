import React, { useEffect, useState } from 'react';
import s from './PatientProfile.module.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContract,
  getPresentById,
  getSignedContract,
} from '../../store/reducers/PresentationReducer';
import Loader from '../Loader/Loader';
import { getSignedMonthlyContract } from '../../store/reducers/PaymentReducer';
import Icon from '../../Icon';
import { formatMiniPhoneNumber, stringToPdf } from '../../utils/utils';
import { saveAs } from 'file-saver';

const PatientProfile = () => {
  const dispatch = useDispatch();
  const { id, date, treatmentId } = useParams();
  const { presentation, contract, loading } = useSelector(
    (state) => state.presentation
  );
  const { monthlyContract, loading: paymentLoading } = useSelector(
    (state) => state.payment
  );
  const [presentationInfo, setPresentationInfo] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);
  const [monthlyContractUrl, setMonthlyContractUrl] = useState(null);

  const handleDownload = (name) => {
    // saveAs(pdf, 'document.pdf');
    if (name === 'contract') {
      saveAs(contractUrl, 'contract.pdf');
    }
    if (name === 'monthlyContract') {
      saveAs(monthlyContractUrl, 'orthoBancForm.pdf');
    }
  };

  useEffect(() => {
    dispatch(getPresentById({ id, date, treatmentId }));
  }, []);

  useEffect(() => {
    setPresentationInfo(presentation);
  }, [presentation]);

  useEffect(() => {
    if (contract) {
      const blob = stringToPdf(contract);
      setContractUrl(blob);
    }
  }, [contract]);

  useEffect(() => {
    if (monthlyContract) {
      const blob = stringToPdf(monthlyContract);
      setMonthlyContractUrl(blob);
    }
  }, [monthlyContract]);

  useEffect(() => {
    if (presentationInfo?.contractUrl) {
      dispatch(getSignedContract({ id }));
    }
    if (presentationInfo?.orthoBancFormUrl) {
      dispatch(getSignedMonthlyContract({ id }));
    }
  }, [presentationInfo]);

  if (loading || paymentLoading) {
    <div className={s.wrapper}>
      <Loader />
    </div>;
  }

  return (
    <div className={s.wrapper}>
      <img
        src={presentationInfo?.organization?.logo || ''}
        alt="avatar"
        width={150}
        height={150}
        style={{ borderRadius: '15px' }}
      />
      <p className={s.text}>
        {`Thank you for completing your financial contract. If you don’t
    already have an appointment scheduled, please call us at ${presentationInfo?.organization?.phones
      .map((phone, index) => formatMiniPhoneNumber(phone))
      .join(' or ')}. We look forward to seeing you soon! `}
      </p>
      {/* <h5 className={s.header}>All documents</h5> */}
      <div className={s.docs}>
        {contractUrl ? (
          <div className={s.contract_wrapper}>
            <div className={s.contract_top}>
              <Icon
                name="document"
                styles={{ width: '50px', height: '100px' }}
              ></Icon>
              <p className={s.contract_title}>Contract</p>
              <div></div>
            </div>
            <button
              className={s.contract_button}
              onClick={() => handleDownload('contract')}
            >
              Open
            </button>
          </div>
        ) : (
          ''
        )}
        {monthlyContractUrl ? (
          <div className={s.month_contract_wrapper}>
            <div className={s.contract_top}>
              <Icon
                name="document"
                styles={{ width: '50px', height: '100px' }}
              ></Icon>
              <p className={s.contract_title}>OrthoBanc Form</p>
              <div></div>
            </div>
            <button
              className={s.contract_button}
              onClick={() => handleDownload('monthlyContract')}
            >
              Open
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <button className={s.logout}>Logout</button>
    </div>
  );
};

export default PatientProfile;
