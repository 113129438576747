import { cumipmt } from '../../utils/utils'

export const findInterestTriggerPoint = (presentationInfo) => {
    // Function to calculate deferred balance
    const calculateDeferredBalance = () => {
        if (!presentationInfo || !presentationInfo.calculations || !presentationInfo.calculations.payments) {
            return 0;
        }
        return Math.max(
            0,
            presentationInfo.calculations.payments.bpContract_calc -
            presentationInfo.calculations.payments.bpSelectedDownpayment_calc -
            presentationInfo.calculations.payments.bpDownPaymentDiscount_calc
        );
    };

    // Function to calculate selected months
    const calculateSelectedMonths = (value) => {
        const divisor = value >= 97 ? 97 : value;
        const deferredBalance = calculateDeferredBalance();
        if (!presentationInfo || !presentationInfo.calculations || !presentationInfo.calculations.payments) {
            return 0;
        }
        return deferredBalance === 0
            ? 0
            : Math.round(presentationInfo.calculations.payments.bpMaximumMonths_calc * ((100 - divisor) / 100));
    };

    // Function to calculate APR
    const calculateArp = (value) => {
        if (!presentationInfo || !presentationInfo.organization || !presentationInfo.treatment) {
            return 0;
        }
        const selectedMonths = calculateSelectedMonths(value);
        const apr = presentationInfo.organization.maxAprForExtendedPayoutTime / 100;
        return parseFloat(
            ((apr * (selectedMonths - presentationInfo.treatment.months)) /
                (presentationInfo.calculations.payments.bpMaximumMonths_calc - presentationInfo.treatment.months)).toFixed(3)
        );
    };

    // Function to calculate interest
    const calculateInterest = (value) => {
        if (!presentationInfo || !presentationInfo.calculations || !presentationInfo.treatment) {
            return 0;
        }
        const selectedMonths = calculateSelectedMonths(value);
        const deferredBalance = calculateDeferredBalance();
        const apr = calculateArp(value);

		// console.log('selectedMonths', selectedMonths);
		// console.log('deferredBalance', deferredBalance);
		// console.log('apr', apr);
        
        if (selectedMonths > presentationInfo.treatment.months && deferredBalance > 0) {
            return -cumipmt(
                apr / 12,
                selectedMonths,
                deferredBalance,
                2,
                selectedMonths,
                0
            );
        }
        return 0;
    };

    // Find the interest trigger point
    let thresholdValue = null;

    for (let value = 100; value >= 1; value--) {
        const interest = calculateInterest(value);
        if (interest > 0) {
            thresholdValue = value;
            break;
        }
    }

    if (thresholdValue !== null) {
        return +((thresholdValue / 99) * 100).toFixed(0);
        // console.log('Interest Trigger Point:', thresholdValue);
        // console.log('Percentage Position:', percentagePosition);
        // return { thresholdValue, percentagePosition };
    } else {
        console.log('Interest is never triggered within the slider range.');
        return null;
    }
};