import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import './PresentationInfo.scss';
import {
  accept,
  getContract,
  getPresent,
  getPresentById,
  patchTreatments,
  postEnabled,
  share,
} from '../../store/reducers/PresentationReducer';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { postConsult } from '../../store/reducers/ConsultReducer';
import { InputNumber, InputGroup, Stack, Toggle } from 'rsuite';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import Icon from '../../Icon';
import { cumipmt, formatMiniPhoneNumber } from '../../utils/utils';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { downloadStyles } from '../ContractDocument/styles';
import toast, { Toaster } from 'react-hot-toast';
import { findInterestTriggerPoint } from './calc';
import ContractDownload from '../ContractDocument/ContractDownload';

const PresentationInfo = () => {
  const dispatch = useDispatch();
  const { id, date, treatmentId } = useParams();
  const { consult, loading, queryParams } = useSelector(
    (state) => state.consult
  );
  const {
    presentation,
    contract,
    loading: presentationLoading,
  } = useSelector((state) => state.presentation);
  const [patient, setPatient] = useState(consult || {});
  const [presentationInfo, setPresentationInfo] = useState(null);
  const [popup, setPopup] = useState(false);
  const [interestFreeZone, setInterestFreeZone] = useState(0);
  // const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  let emailsArray = [];

  const handleContract = () => {
    if (presentationInfo.status < 2) {
      const data = {
        treatmentId: treatmentId,
        patientId: id,
      };
      dispatch(accept(data)).then(() => {
        navigate(`/contract/${id}/${date}/${treatmentId}`);
      });
    } else {
      navigate(`/contract/${id}/${date}/${treatmentId}`);
    }
  };

  const handleClose = () => {
    navigate(`/consultations/${id}`);
  };

  useEffect(() => {
    if(emailsArray.length === 0 && presentation) {
      emailsArray = [presentation?.email]
    }
    setPresentationInfo(presentation);
  }, [presentation, emailsArray]);

  useEffect(() => {
    setPatient(consult);
  }, [consult]);

  useEffect(() => {
  }, [consult]);

  useEffect(() => {
    if (patient && patient.treatments) {      
      const selectedTreatment = patient.treatments.find(
        (item) => item.treatmentId === +treatmentId
      );
      if (selectedTreatment.months === 0 || patient.isGuaranteedFunds) {
        dispatch(
          patchTreatments({
            patientId: +id,
            selectedTreatmentId: +treatmentId,
            downPaymentSlider: 400,
            monthlyPaymentSlider: 0,
          })
        ).then(() => {
          dispatch(getPresentById({ id, date, treatmentId }));
        });
      } else if (selectedTreatment.months !== 0) {
        dispatch(
          patchTreatments({
            patientId: +id,
            selectedTreatmentId: +treatmentId,
            downPaymentSlider: patient.downPaymentSlider ? patient.downPaymentSlider : 100,
            monthlyPaymentSlider: patient.monthlyPaymentSlider ? patient.monthlyPaymentSlider : 40,
          })
        ).then(() => {
          dispatch(getPresentById({ id, date, treatmentId }));
        });
      }
    }
  }, [patient.id, treatmentId]);

  // const calcDisabled = () => {
  // 	const selectedTreatment = patient.treatments.find(item => item.treatmentId === +treatmentId);
  // 	if (selectedTreatment.months === 0) {
  // 		return true
  // 	}
  // 	return false;
  // };

  useEffect(() => {
    console.log(presentationInfo);
    
  }, [presentationInfo])

  useEffect(() => {
    const selectedTreatment = patient?.treatments?.find(
      (item) => item.treatmentId === +treatmentId
    );
    console.log('selectedTreatment', selectedTreatment);
    if (selectedTreatment?.months === 0 || patient?.isGuaranteedFunds || presentation?.calculations?.payments?.bpContract_calc === 0) {
      setIsDisabled(true);
    }
    else {
      setIsDisabled(false)
    }
  }, [patient, presentation]);

  // const transformPatientData = data => {
  // 	const transformedTreatments = data.treatments.map(treatment => ({
  // 		treatmentTypeId: treatment.treatmentId,
  // 		addOns: treatment.addOns.map(addOn => ({
  // 			addOnId: addOn.id,
  // 			required: addOn.required,
  // 			enabled: addOn.enabled
  // 		}))
  // 	}));

  // 	return {
  // 		id: data.id,
  // 		locationId: data.locationId,
  // 		discountId: data.discountId,
  // 		firstName: data.firstName,
  // 		lastName: data.lastName,
  // 		patientNumber: data.patientNumber,
  // 		email: data.email,
  // 		orthoBancNumber: data.orthoBancNumber,
  // 		usedToDate: data.usedToDate,
  // 		maxLifeTime: data.maxLifeTime,
  // 		percentage: data.percentage,
  // 		deductible: data.deductible,
  // 		prevContractCredit: data.prevContractCredit,
  // 		isGuaranteedFunds: data.isGuaranteedFunds,
  // 		avatarBackground: data.avatarBackground,
  // 		treatments: transformedTreatments
  // 	};
  // };

  const toggleChange = (item) => {
    // const newPatient = _.cloneDeep(patient);
    const newItem = {
      id: item.treatmentAddonId,
    };

    try {
      dispatch(postEnabled({ item: newItem })).then((res) => {
        if (res.error) {
          toast.error(`Error: ${res.error.message}`);
        } else {
          dispatch(getPresentById({ id, date, treatmentId }));
        }
      });
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
    // newPatient.treatments.forEach(treatment => {
    // 	if (treatment.treatmentId === item.treatmentId) {
    // 		treatment.addOns.forEach(addOn => {
    // 			if (item.treatmentAddonId === addOn.treatmentAddonId) {
    // 				addOn.enabled = !addOn.enabled;
    // 			}
    // 		});
    // 	}
    // });

    // const transformedData = transformPatientData(newPatient);
    // try {
    // 	dispatch(postConsult({ item: transformedData })).then(res => {
    // 		if (res.error) {
    // 			toast.error(`Error: ${res.error.message}`);
    // 		} else {
    // 			dispatch(getPresentById({ id, date, treatmentId }));
    // 		}
    // 	});
    // } catch (error) {
    // 	toast.error(`Error: ${error.message}`);
    // }
  };

  function ceiling(value, step) {
    return Math.ceil(value / step) * step;
  }

  const bpDownPaymentDiscount_calc = (value) => {
    const bpContract_calc =
      presentationInfo.calculations.payments.bpContract_calc.toFixed(0);
    const discount =
      (value / 400) *
      bpContract_calc *
      (presentationInfo.organization.maxPayoutDiscount / 100);
    const isDiscountApplicable =
      presentationInfo.treatment.months === 0 ||
      presentationInfo.isGuaranteedFunds ||
      value / 400 < presentationInfo.organization.payoutDiscountBeginsAt / 100;

    setPresentationInfo((prev) => ({
      ...prev,
      calculations: {
        ...prev.calculations,
        payments: {
          ...prev.calculations.payments,
          bpDownPaymentDiscount_calc: isDiscountApplicable
            ? 0
            : ceiling(discount, 25),
        },
      },
    }));

    return isDiscountApplicable ? 0 : ceiling(discount, 25);
  };

  const calcDiscount = (e, value) => {
    setIsSaved(false);
    setPresentationInfo((prev) => ({ ...prev, downPaymentSlider: value }));
    const bpContract_calc =
      presentationInfo.calculations.payments.bpContract_calc.toFixed(0);
    let bpSelectedDownpayment_calc;

    if (
      presentationInfo.treatment.months === 0 ||
      presentationInfo.isGuaranteedFunds
    ) {
      bpSelectedDownpayment_calc = +bpContract_calc;
    } else {
      const ceiling1 = ceiling(
        bpContract_calc / (presentationInfo.treatment.months + 1),
        1
      );
      const ceiling2 = ceiling(
        bpContract_calc * (presentationInfo.organization.minDownFee / 100),
        1
      );
      const ceiling3 = ceiling((bpContract_calc * value) / 400, 25);
      const max_calc = Math.max(ceiling1, ceiling2, ceiling3);

      bpSelectedDownpayment_calc =
        max_calc > bpContract_calc - bpDownPaymentDiscount_calc(value)
          ? bpContract_calc - bpDownPaymentDiscount_calc(value)
          : max_calc;
    }

    bpMonthlyPayment_calc(null, presentationInfo.monthlyPaymentSlider);
    bpSelectedMonths_calc(presentationInfo.monthlyPaymentSlider);

    setPresentationInfo((prev) => ({
      ...prev,
      calculations: {
        ...prev.calculations,
        payments: {
          ...prev.calculations.payments,
          bpSelectedDownpayment_calc,
        },
      },
    }));
  };

  const bpDefferedBalance_calc = () => {
    const defferendBalance = Math.max(
      0,
      presentationInfo.calculations?.payments.bpContract_calc -
        presentationInfo.calculations?.payments.bpSelectedDownpayment_calc -
        presentationInfo.calculations?.payments.bpDownPaymentDiscount_calc
    );
    return defferendBalance;
  };

  const bpSelectedMonths_calc = (value) => {
    const selectedMounths =
      bpDefferedBalance_calc() === 0
        ? 0
        : value === 99
        ? Math.ceil(
            presentationInfo.calculations?.payments.bpMaximumMonths_calc *
              ((100 - value) / 100)
          )
        : Math.round(
            presentationInfo.calculations?.payments.bpMaximumMonths_calc *
              ((100 - value) / 100)
          );
    setPresentationInfo((prev) => ({
      ...prev,
      calculations: {
        ...prev.calculations,
        payments: {
          ...prev.calculations.payments,
          bpSelectedMonths_calc: selectedMounths,
        },
      },
    }));
    return selectedMounths;
  };

  const bpArp_calc = (value) => {
    const arp = parseFloat(
      (
        (presentationInfo.organization.maxAprForExtendedPayoutTime / 100) *
        ((bpSelectedMonths_calc(value) - presentationInfo.treatment.months) /
          (presentationInfo.calculations?.payments.bpMaximumMonths_calc -
            presentationInfo.treatment.months))
      ).toFixed(3)
    );
    return arp;
  };

  const bpInterest_calc = (value) => {
    const interest =
      bpSelectedMonths_calc(value) > presentationInfo.treatment?.months &&
      bpDefferedBalance_calc() > 0
        ? -cumipmt(
            bpArp_calc(value) / 12,
            bpSelectedMonths_calc(value),
            bpDefferedBalance_calc(),
            2,
            bpSelectedMonths_calc(value),
            0
          )
        : 0;
    setPresentationInfo((prev) => ({
      ...prev,
      calculations: {
        ...prev.calculations,
        payments: {
          ...prev.calculations.payments,
          bpInterest_calc: interest,
        },
      },
    }));

    return interest;
  };

  const bpMonthlyPayment_calc = (e, value) => {
    setIsSaved(false);
    setPresentationInfo((prev) => ({ ...prev, monthlyPaymentSlider: value }));
    const mounthlyPayment =
      bpDefferedBalance_calc() === 0
        ? 0
        : ceiling(
            (bpDefferedBalance_calc() + bpInterest_calc(value)) /
              bpSelectedMonths_calc(value),
            1
          );

    setPresentationInfo((prev) => ({
      ...prev,
      calculations: {
        ...prev.calculations,
        payments: {
          ...prev.calculations.payments,
          bpMonthlyPayment_calc: mounthlyPayment,
        },
      },
    }));
  };

  const handleEmail = (e) => {
    const inputText = e.target.value;
    const emails = inputText.split(',').map((email) => email.trim());
    emailsArray = emails.filter((email) => email !== '');
  };

  const handleShare = () => {
    const data = {
      shareItem: {
        patientId: id,
        emails: emailsArray,
      },
    };

    dispatch(share(data)).then(() => {
      dispatch(getPresentById({ id, date, treatmentId }))
    });
    setPopup(false);
  };

  const handleSliderPositionChange = (value) => {
    dispatch(
      patchTreatments({
        patientId: +id,
        selectedTreatmentId: +treatmentId,
        downPaymentSlider: presentationInfo.downPaymentSlider,
        monthlyPaymentSlider: presentationInfo.monthlyPaymentSlider,
      })
    ).then(() => {
      dispatch(getPresentById({ id, date, treatmentId }));
    });
  };

  // const handleMonthlyPaymentChange = value => {
  // 	dispatch(
  // 		patchTreatments({
  // 			patientId: +id,
  // 			selectedTreatmentId: +treatmentId,
  // 			downPaymentSlider: presentationInfo.downPaymentSlider,
  // 			monthlyPaymentSlider: presentationInfo.monthlyPaymentSlider
  // 		})
  // 	).then(() => {
  // 		dispatch(getPresentById({ id, date, treatmentId }));
  // 	});
  // };

  const handleDiscard = () => {
    setPresentationInfo((prev) => {
      return {
        ...prev,
        downPaymentSlider: presentation.downPaymentSlider,
        monthlyPaymentSlider: presentation.monthlyPaymentSlider,
      };
    });
    setIsSaved(true);
    toast.dismiss();
  };

  const handleDownloadClick = () => {
    toast.dismiss();
    const handleSave = () => {
      dispatch(
        patchTreatments({
          patientId: +id,
          selectedTreatmentId: +treatmentId,
          downPaymentSlider: presentationInfo.downPaymentSlider,
          monthlyPaymentSlider: presentationInfo.monthlyPaymentSlider,
        })
      ).then(() => {
        dispatch(getPresentById({ id, date, treatmentId })).then(() => {
          setIsSaved(true);
          if (presentationInfo.status < 2) {
            const data = {
              treatmentId: treatmentId,
              patientId: id,
            };
            dispatch(accept(data)).then(() => {
              navigate(`/contract/${id}/${date}/${treatmentId}`);
            });
          } else {
            navigate(`/contract/${id}/${date}/${treatmentId}`);
          }
        });
      });
    };

    toast((t) => (
      <span>
        <b>Do you want to save your changes before accepting?</b>
        <div className="save-toast">
          <button
            onClick={() => {
              toast.dismiss(t.id);
              handleSave();
            }}
            className="save-toast-yes"
          >
            Yes
          </button>
          <button onClick={() => handleDiscard()} className="save-toast-no">
            No
          </button>
        </div>
      </span>
    ));
  };

  // useEffect(() => {
  // 	if (isConfirmed && downloadLinkRef.current) {
  // 		downloadLinkRef.current.click();
  // 		setIsConfirmed(false);
  // 	}
  // }, [isConfirmed]);

  useEffect(() => {
    const result = findInterestTriggerPoint(presentation);
    setInterestFreeZone(result);
  }, [presentation]);

  useEffect(() => {
  }, [interestFreeZone]);

  if (
    loading ||
    presentationLoading ||
    !presentationInfo ||
    !presentationInfo.calculations ||
    !presentationInfo.calculations.payments
  ) {
    return (
      <div className="present">
        <Loader />
      </div>
    );
  }

  function discountText() {
    return `$${presentationInfo.calculations.payments.bpSelectedDownpayment_calc.toFixed(
      2
    )}`;
  }

  function monthlyText() {
    return `$${presentationInfo.calculations.payments.bpMonthlyPayment_calc.toFixed(
      2
    )}`;
  }

  return (
    <>
      <Toaster position="top-right" toastOptions={{ duration: 15000 }} />
      <div className="present" id="present">
        <div className={popup ? 'popup' : 'hide-popup'}>
          <div className="input-block">
            <div className="popup-close" onClick={() => setPopup(false)}>
              <Icon name="close" styles={{ fill: 'black', width: '20' }}></Icon>
            </div>
            <h5 className="popup-header">Share the presentation</h5>
            <p className="instruction">
              Please, enter the comma-separated list of emails
            </p>
            <p className="example">
              example1@email.com, example2@email.com, ...
            </p>
            <textarea
              type="text"
              placeholder={`example1@gmail.com, example2@gmail.com`}
              defaultValue={`${presentation?.email}`}
              onChange={handleEmail}
            />
            <button className="share-button" onClick={handleShare}>
              Share
            </button>
          </div>
        </div>
        <div className="present-empty"></div>
        <div className="present-details">
          <div className="present-details-title">
            <img
              src={presentationInfo.organization.logo || ''}
              alt="avatar"
              width={150}
              height={150}
              style={{ borderRadius: '15px' }}
            />
            <div className="present-details-title-text">
              <div className="present-details-title-header">
                Financial Consult Form
              </div>
              <div className="present-details-title-info">
                {presentationInfo.calculations.payments.treatmentType_calc}
              </div>
            </div>
          </div>
          <div className="present-details-info">
            <div className="present-details-info-block">
              <b className="present-details-info-block-title">Patient Name:</b>
              <p className="present-details-info-block-value">
                {presentationInfo.firstName} {presentationInfo.lastName}
              </p>
            </div>
            <div className="present-details-info-block">
              <b className="present-details-info-block-title">
                Patient Number:
              </b>
              <p className="present-details-info-block-value">
                {presentationInfo.patientNumber}
              </p>
            </div>
            <div className="present-details-info-block">
              <b className="present-details-info-block-title">Treatment Fee:</b>
              <p className="present-details-info-block-value">
                $
                {presentationInfo.calculations.payments.treatmentFee_calc.toFixed(
                  2
                )}
              </p>
            </div>
            <div className="present-details-info-block">
              <b className="present-details-info-block-title">
                Less Credit from Previous Contract:
              </b>
              <p className="present-details-info-block-value">
                $
                {presentationInfo.calculations.payments.creditFromPreviousContract.toFixed(
                  2
                )}
              </p>
            </div>
            <div className="present-details-info-block">
              <b className="present-details-info-block-title">
                {
                  presentationInfo.calculations.payments
                    .discountDescription_calc
                }
              </b>
              <p className="present-details-info-block-value">
                $
                {presentationInfo.calculations.payments.discount_calc.toFixed(
                  2
                )}
              </p>
            </div>
            <div className="present-details-info-block">
              <b className="present-details-info-block-title">
                Less Estimated Insurance Benefit:
              </b>
              <p className="present-details-info-block-value">
                $
                {presentationInfo?.calculations?.payments?.insContract_calc.toFixed(
                  2
                )}
              </p>
            </div>
          </div>
          <div className="present-details-addons">
            <div className="present-details-addons-list">
              <h5 className="present-details-addons-list-header">Add-Ons</h5>
              {presentationInfo.treatment?.addOns?.length > 0 ? (
                [...presentationInfo.treatment.addOns]
                  .sort((a, b) => b.order - a.order)
                  .map((item) => {
                    if (
                      item.hasOwnProperty('required') &&
                      item.hasOwnProperty('enabled')
                    ) {
                      return (
                        <div
                          className={`present-info-complex-elements-card`}
                          key={item.id}
                        >
                          <div className="present-info-complex-elements-card-main">
                            <b className="present-info-complex-elements-card-main-title">
                              {item.name}
                            </b>
                            <div className="present-info-complex-elements-card-main-price">
                              ${item.fee}.00
                            </div>
                          </div>
                          <div className="present-info-complex-elements-card-sub">
                            {!item.required && (
                              <Toggle
                                checked={item.enabled}
                                onChange={() => toggleChange(item)}
                                className="present-info-complex-elements-card-toggle"
                              ></Toggle>
                            )}
                            {/* {isEdit ? (
																	<>
																		<div className="patient-info-complex-elements-card-required-edit">
																			<p>Set as required</p>
																			<Toggle
																				checked={item.required}
																				onChange={() =>
																					onRequireAddOn(item)
																				}></Toggle>
																			<div
																				className="delete-addons"
																				onClick={() => onDeleteAddOn(item)}>
																				<Icon name="delete"></Icon>
																			</div>
																		</div>
																	</>
																) : ( */}
                            <div
                              className={`present-info-complex-elements-card${
                                item.enabled ? '-required' : '-optional'
                              }`}
                            >
                              {item.required
                                ? 'Required'
                                : item.enabled
                                ? 'Selected'
                                : 'Optional'}
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })
              ) : (
                <>This Treatment Type does not have Add-Ons</>
              )}
              {/* <div className="present-details-addons-list-addon">
								<div className="treatment-addon-wrapper-details">
									<div className="present-details-addons-list-addon-right-title">Ceramic Braces</div>
									<div className="present-details-addons-list-addon-right-fees">$600.00</div>
								</div>
								<div className="present-details-addons-list-addon-req">Required</div>
							</div>
							<div className="present-details-addons-list-addon">
								<div className="present-details-addons-list-addon-right">
									<div className="present-details-addons-list-addon-right-title">
										Upper Ceramic Braces
									</div>
									<div className="present-details-addons-list-addon-right-fees">$300.00</div>
								</div>
								<div className="present-details-addons-list-addon-checkbox"></div>
							</div> */}
            </div>
            <div className="present-details-addons-invest">
              <div className="present-details-addons-invest-title">
                NET PATIENT INVESTMENT:
              </div>
              <div className="present-details-addons-invest-fee">
                $
                {presentationInfo.calculations.payments.bpContract_calc.toFixed(
                  2
                )}
              </div>
            </div>
            <div className="present-details-addons-payment">
              <p>Choose a down payment to reduce your monthly payments:</p>
              {/* <InputNumber className="presentation-input" value={presentationInfo.downPaymentSlider} /> */}
              {isDisabled ? (
                <></>
              ) : (
                <div className="active-section-wrapper">
                  <div
                    className="active-section"
                    style={{
                      width: `calc(100% - ${presentationInfo.organization.payoutDiscountBeginsAt}%)`,
                    }}
                  >
                    <p>DISCOUNTS AVAILABLE</p>
                  </div>
                </div>
              )}
              <Slider
                value={presentationInfo?.downPaymentSlider}
                min={0}
                max={400}
                valueLabelDisplay="auto"
                valueLabelFormat={discountText}
                onChange={calcDiscount}
                disabled={isDisabled}
                onChangeCommitted={(event, value) => {
                  toast.dismiss();
                  toast((t) => (
                    <span>
                      <b>Do you want to save your changes?</b>
                      <div className="save-toast">
                        <button
                          onClick={() => {
                            toast.dismiss();
                            handleSliderPositionChange(value);
                          }}
                          className="save-toast-yes"
                        >
                          Yes
                        </button>
                        <button
                          onClick={() => handleDiscard()}
                          className="save-toast-no"
                        >
                          No
                        </button>
                      </div>
                    </span>
                  ));
                }}
              />
            </div>
            <div className="present-details-addons-paying">
              <p>Choose a monthly payment that you are comfortable paying:</p>
              {/* <InputNumber className="presentation-input" value={presentationInfo.monthlyPaymentSlider} /> */}
              {isDisabled ? (
                <></>
              ) : (
                <div className="active-section-wrapper">
                  <div
                    className="active-section"
                    style={{
                      width: `calc(100% - ${interestFreeZone}%)`,
                    }}
                  >
                    <p>INTEREST-FREE PAYMENTS</p>
                  </div>
                </div>
              )}
              <Slider
                value={
                  presentationInfo?.downPaymentSlider === 400
                    ? 0
                    : presentationInfo?.monthlyPaymentSlider
                }
                valueLabelDisplay="auto"
                // step={1}
                min={0}
                max={99}
                valueLabelFormat={monthlyText}
                disabled={isDisabled}
                onChange={bpMonthlyPayment_calc}
                onChangeCommitted={(event, value) => {
                  toast.dismiss();
                  toast((t) => (
                    <span>
                      <b>Do you want to save your changes?</b>
                      <div className="save-toast">
                        <button
                          onClick={() => {
                            toast.dismiss();
                            handleSliderPositionChange(value);
                          }}
                          className="save-toast-yes"
                        >
                          Yes
                        </button>
                        <button
                          onClick={() => handleDiscard()}
                          className="save-toast-no"
                        >
                          No
                        </button>
                      </div>
                    </span>
                  ));
                }}
              />
            </div>
          </div>
            <div className="present-details-addons-calc">
              {presentationInfo.calculations.payments
                .bpDownPaymentDiscount_calc === 0 ? (
                <></>
              ) : (
                <>
                  <div className="present-details-addons-calc-block">
                    <div className="present-details-addons-calc-block-title">
                      $
                      {presentationInfo.calculations.payments.bpDownPaymentDiscount_calc.toFixed(
                        2
                      )}
                    </div>
                    <div className="present-details-addons-calc-block-info">
                      DISCOUNT AMOUNT
                    </div>
                  </div>
                </>
              )}
              <div className="present-details-addons-calc-block">
                <div className="present-details-addons-calc-block-title">
                  $
                  {presentationInfo.calculations.payments.bpSelectedDownpayment_calc.toFixed(
                    2
                  )}
                </div>
                <div className="present-details-addons-calc-block-info">
                  DOWN PAYMENT
                </div>
              </div>
              <div className="present-details-addons-calc-block">
                <div className="present-details-addons-calc-block-title">
                  $
                  {presentationInfo.calculations.payments.bpMonthlyPayment_calc.toFixed(
                    2
                  )}
                </div>
                <div className="present-details-addons-calc-block-info">
                  MONTHLY PAYMENT
                </div>
              </div>
              <div className="present-details-addons-calc-block">
                <div className="present-details-addons-calc-block-title">
                  {presentationInfo.calculations.payments.bpSelectedMonths_calc}
                </div>
                <div className="present-details-addons-calc-block-info">
                  NUMBER OF MONTHS
                </div>
              </div>
              {presentationInfo.calculations.payments.bpInterest_calc ===
              0 ? null : (
                <>
                  <div className="present-details-addons-calc-block">
                    <div className="present-details-addons-calc-block-title">
                      $
                      {presentationInfo.calculations.payments.bpInterest_calc.toFixed(
                        2
                      )}
                    </div>
                    <div className="present-details-addons-calc-block-info">
                      TOTAL INTEREST
                    </div>
                  </div>
                </>
              )}
            </div>
          <div className="present-details-notes">
            <h4 className="present-details-notes-title">Important Notes:</h4>
            <div className="present-details-notes-text">
              {presentationInfo.calculations.notes.notesPrerequisites_calc}
            </div>
            <div className="present-details-notes-text">
              {presentationInfo.calculations.notes.notesPayment_calc}
            </div>
            <div className="present-details-notes-text">
              {presentationInfo.calculations.notes.notesInsurance_calc}
            </div>
            <div className="present-details-notes-text">
              {presentationInfo.calculations.notes.notesCoveredServices_calc}
            </div>
            <div className="present-details-notes-text last">
              {presentationInfo.calculations.notes.notesCoveredServices_calc2}
            </div>
            {/* <div className="present-details-notes-accept">
							<input type="checkbox" className="present-details-notes-accept-checkbox" defaultValue={presentationInfo.status >= 2 ? true : false} />
							<div className="present-details-notes-accept-title">ACCEPT</div>
						</div> */}
          </div>
          <div className="present-details-contacts">
            <b className="present-details-contacts-string">
              {presentationInfo.organization.name}
            </b>
            <b className="present-details-contacts-string">
              {presentationInfo.organization.tagLine}
            </b>
            <b className="present-details-contacts-string">
              {presentationInfo.organization.phones.map((phone) => {
                return ` ${formatMiniPhoneNumber(phone)} |`;
              })}{' '}
              {presentationInfo.organization.webSite}
            </b>
          </div>
          <div className="present-details-buttons">
            <button
              className="present-details-share-button"
              onClick={() => setPopup(true)}
            >
              Share
            </button>
            {isSaved ? (
              <button
                className="present-details-next-button"
                onClick={handleContract}
                disabled={presentation.status === 0}
              >
                Accept
              </button>
            ) : (
              <button
                className="present-details-next-button"
                onClick={handleDownloadClick}
                disabled={presentation.status === 0}
              >
                Save & Accept
              </button>
            )}
            {/* <button className="present-details-next-button" onClick={handleContract} disabled={!isSaved}>
							Accept
						</button> */}
          </div>
        </div>
        <div className="present-close" onClick={handleClose}>
          <Icon name="close" styles={{ fill: '#7D8485', width: '35' }}></Icon>
        </div>
      </div>
    </>
  );
};

export default PresentationInfo;
