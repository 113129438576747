import React, { useState, useEffect } from 'react';
import './Profile.scss';

import { useNavigate } from 'react-router-dom';
import { users } from '../../data';
import Icon from '../../Icon';

import { useDispatch } from 'react-redux';
import { logout, sendCode } from '../../store/reducers/AuthReduser';
import toast, { Toaster } from 'react-hot-toast'

const ProfileReset = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const sendConfirmationCode = () => {
    const send = () => {
      dispatch(sendCode({ id: user.id, email: user.email })).then(() => {
        navigate('/profile/reset');
      });
    };

    toast((t) => (
      <span>
        <b>Do you want to email a confirmation code?</b>
        <div className="save-toast">
          <button
            onClick={() => {
              toast.dismiss(t.id);
              send()
            }}
            className="save-toast-yes"
          >
            Yes
          </button>
          <button onClick={() => toast.dismiss(t.id)} className="save-toast-no">
            No
          </button>
        </div>
      </span>
    ));
  };

  useEffect(() => {
    setEmail(user.email);
  }, []);

  return (
    <>
    <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
      <div className="profile-confirmation">
        <div className="profile-confirmation-header">
          <b className="profile-confirmation-header-details">
            Reset email or password
          </b>
        </div>
        <div className="profile-confirmation-details">
          <div className="profile-confirmation-details-title">Email *</div>
          <input
            className="profile-confirmation-details-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          ></input>
          <div className="profile-confirmation-details-button">
            <button
              className="profile-confirmation-details-button-content"
              onClick={sendConfirmationCode}
            >
              Update Email Address or Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileReset;
