import React, { useEffect, useRef, useState } from 'react';
import './MonthlyPaymentForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getPresentById } from '../../store/reducers/PresentationReducer';
import Loader from '../Loader/Loader';
import { PDFViewer } from '@react-pdf/renderer';
import MonthlyPaymentDownload from '../ContractDocument/MonthlyPaymentDownload';
import { monthlyPaymentDownloadStyles } from '../ContractDocument/styles';
import toast, { Toaster } from 'react-hot-toast';
import {
  getConsultsById,
  updateConsult,
} from '../../store/reducers/ConsultReducer';
import SignatureCanvas from 'react-signature-canvas';

const MonthlyPaymentForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { presentation, loading } = useSelector((state) => state.presentation);
  const { consult, loading: consultLoading } = useSelector(
    (state) => state.consult
  );
  const [signature, setSignature] = useState(null);
  const sigCanvas = useRef({});
  const { id, date, treatmentId } = useParams();
  const [patientInfo, setPatientInfo] = useState(null);
  const [isBank, setIsBank] = useState(true);
  //   const [formData, setFormData] = useState({
  //     firstName: '',
  //     lastName: '',
  //     streetAddress: '',
  //     city: '',
  //     state: '',
  //     zipCode: '',
  //     phoneNumber: '',
  //     email: '',
  //     checking: false,
  //     saving: false,
  //     routingNumber: '',
  //     accountNumber: '',
  //     cardNumber: '',
  //     expirationDate: '',
  //     cvvCode: '',
  //   });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    let newValue;

    if (type === 'date') {
      if (value) {
        const dateObject = new Date(value);
        newValue = dateObject?.toISOString();
      }
    } else {
      newValue = type === 'checkbox' ? checked : value;
    }

    setPatientInfo((prev) => {
      return { ...prev, [name]: newValue };
    });
  };

  const validate = () => {
    let errors = {};

    if (!patientInfo.orthoFirstName) {
      errors.orthoFirstName = 'First Name is required';
      toast.error('First Name is required');
    }
    if (!patientInfo.orthoLastName) {
      errors.orthoLastName = 'Last Name is required';
      toast.error('Last Name is required');
    }
    if (!patientInfo.address) {
      errors.address = 'Street Address is required';
      toast.error('Street Address is required');
    }
    if (!patientInfo.city) {
      errors.city = 'City is required';
      toast.error('City is required');
    }
    if (!patientInfo.state) {
      errors.state = 'State is required';
      toast.error('State is required');
    }
    if (!patientInfo.zipCode) {
      errors.zipCode = 'Zip Code is required';
      toast.error('Zip Code is required');
    }
    if (!patientInfo.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required';
      toast.error('Phone Number is required');
    }
    if (!patientInfo.orthoEmail) {
      errors.orthoEmail = 'Email is required';
      toast.error('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(patientInfo.orthoEmail)) {
      errors.orthoEmail = 'Email address is invalid';
      toast.error('Email address is invalid');
    }

    if (isBank) {
      if (!patientInfo.routingNumber) {
        errors.routingNumber = 'Routing Number is required';
        toast.error('Routing Number is required');
      }
      if (!patientInfo.accountNumber) {
        errors.accountNumber = 'Account Number is required';
        toast.error('Account Number is required');
      }
    } else {
      if (!patientInfo.cardNumber) {
        errors.cardNumber = 'Card Number is required';
        toast.error('Card Number is required');
      }
      if (!patientInfo.expirationDate) {
        errors.expirationDate = 'Expiration Date is required';
        toast.error('Expiration Date is required');
      }
      if (!patientInfo.cvvCode) {
        errors.cvvCode = 'CVV Code is required';
        toast.error('CVV Code is required');
      }
      if (!patientInfo.orthoDate) {
        errors.orthoDate = 'Date is required';
        toast.error('Date is required');
      }
      //   if (!patientInfo.signLink) {
      //     errors.cvvCode = 'Sign is required';
      //     toast.error('Sign is required');
      //   }
    }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  //   const handleNext = () => {
  //     const result = validate();
  //     console.log(result);
  //     if (result) {
  //       localStorage.setItem('monthlyPaymentData', JSON.stringify(formData));
  //       // navigate(`/monthly-payment-contract/${id}/${date}/${treatmentId}`);
  //     }
  //   };

  useEffect(() => {
    if (!presentation) {
      dispatch(getPresentById({ id, date, treatmentId }));
    }
    dispatch(getConsultsById({ id }));
  }, []);

  useEffect(() => {
    console.log(patientInfo);
  }, [patientInfo]);

  useEffect(() => {
    setPatientInfo(consult);
  }, [consult]);

  //   useEffect(() => {
  //     const savedData = localStorage.getItem('monthlyPaymentData');
  //     if (savedData) {
  //       setFormData(JSON.parse(savedData));
  //     }
  //   }, [presentation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const result = validate();
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

    const blob = await fetch(dataURL).then((res) => res.blob());

	const file = new File([blob], 'signature.png', { type: 'image/png' });

	const patientData = {
		id: patientInfo.id,
		firstName: patientInfo.firstName,
		lastName: patientInfo.lastName,
		email: patientInfo.email,
		patientNumber: patientInfo.patientNumber,
		orthoFirstName: patientInfo.orthoFirstName,
		orthoLastName: patientInfo.orthoLastName,
		orthoEmail: patientInfo.orthoEmail,
		signLink: patientInfo.signLink,
		address: patientInfo.address,
		city: patientInfo.city,
		state: patientInfo.state,
		zipCode: patientInfo.zipCode,
		phoneNumber: patientInfo.phoneNumber,
		checking: patientInfo.checking,
		saving: patientInfo.saving,
		routingNumber: patientInfo.routingNumber,
		accountNumber: patientInfo.accountNumber,
		cardNumber: patientInfo.cardNumber,
		expDate: patientInfo.expDate,
		cvvCode: patientInfo.cvvCode,
    orthoDate: patientInfo.orthoDate
	}

    dispatch(updateConsult({ patient: patientData, file: file }));
  };

  if (loading || consultLoading) {
    return (
      <div className="payment-wrapper">
        <div className="payment-block">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <Toaster position="top-right" />
      <div className="monthly-payment-form-wrapper">
        <img src="" alt="" className="ortho-bank" />
        <h4 className="monthly-payment-form-header">
          Monthly Payment Account Information
        </h4>
        <p className="monthly-payment-form-subheader">FMY Orthodontics, PC</p>
        <p className="monthly-payment-form-title">TYPE OF ACCOUNT</p>
        <div className="payment-form-buttons">
          <button
            className={`monthly-payment-form-button ${
              isBank ? 'active-button' : null
            }`}
            onClick={() => setIsBank(true)}
          >
            Bank Account
          </button>
          <button
            className={`monthly-payment-form-button ${
              isBank ? null : 'active-button'
            }`}
            onClick={() => setIsBank(false)}
          >
            Credit / Debit Card
          </button>
        </div>
        <form onSubmit={handleSubmit} className="monthly-payment-form">
          <div className="monthly-payment-name-section">
            <div className="monthly-payment-title-block">
              Name on the Account
            </div>
            <div className="monthly-payment-input-block-wrapper">
              <div className="monthly-payment-input-block-half">
                <p className="monthly-payment-input-title">First Name</p>
                <input
                  type="text"
                  className="monthly-payment-input"
                  name="orthoFirstName"
                  value={patientInfo?.orthoFirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="monthly-payment-input-block-half">
                <p className="monthly-payment-input-title">Last Name</p>
                <input
                  type="text"
                  className="monthly-payment-input"
                  name="orthoLastName"
                  value={patientInfo?.orthoLastName}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="monthly-payment-name-section">
            <div className="monthly-payment-title-block">Billing Address</div>
            <div className="monthly-payment-input-block-full">
              <p className="monthly-payment-input-title">Street Address</p>
              <input
                type="text"
                className="monthly-payment-input"
                name="address"
                value={patientInfo?.address}
                onChange={handleChange}
              />
            </div>
            <div className="monthly-payment-input-block-wrapper">
              <div className="monthly-payment-input-block-half">
                <p className="monthly-payment-input-title">City</p>
                <input
                  type="text"
                  className="monthly-payment-input"
                  name="city"
                  value={patientInfo?.city}
                  onChange={handleChange}
                />
              </div>
              <div className="monthly-payment-input-block-half">
                <p className="monthly-payment-input-title">State / Province</p>
                <input
                  type="text"
                  className="monthly-payment-input"
                  name="state"
                  value={patientInfo?.state}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="monthly-payment-input-block-full">
              <p className="monthly-payment-input-title">Postal / Zip Code</p>
              <input
                type="text"
                className="monthly-payment-input"
                name="zipCode"
                value={patientInfo?.zipCode}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="monthly-payment-name-section-wrapper">
            <div className="monthly-payment-name-section-half">
              <div className="monthly-payment-title-block">
                Phone Number Associated with Account
              </div>
              <div className="monthly-payment-input-block-wrapper">
                <div className="monthly-payment-input-block-full">
                  <p className="monthly-payment-input-title">
                    Please enter a valid phone number
                  </p>
                  <input
                    type="text"
                    className="monthly-payment-input"
                    name="phoneNumber"
                    value={patientInfo?.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="monthly-payment-name-section-half">
              <div className="monthly-payment-title-block">Email Address</div>
              <div className="monthly-payment-input-block-wrapper">
                <div className="monthly-payment-input-block-full">
                  <p className="monthly-payment-input-title">
                    example@example.com
                  </p>
                  <input
                    type="email"
                    className="monthly-payment-input"
                    name="orthoEmail"
                    value={patientInfo?.orthoEmail}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="monthly-payment-name-section">
            <div className="monthly-payment-title-block">
              Account Information
            </div>
            <div className="monthly-payment-account-info">
              <div className="monthly-payment-left">
                {isBank && (
                  <div className="monthly-payment-checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="monthly-payment-checkbox"
                      name="checking"
                      checked={patientInfo?.checking || false}
                      onChange={handleChange}
                    />
                    <p className="monthly-payment-checkbox-text">Checking</p>
                    <input
                      type="checkbox"
                      className="monthly-payment-checkbox"
                      name="saving"
                      checked={patientInfo?.saving || false}
                      onChange={handleChange}
                    />
                    <p className="monthly-payment-checkbox-text">Savings</p>
                  </div>
                )}
                <div className="monthly-payment-number-input-wrapper">
                  {isBank ? (
                    <>
                      <div className="monthly-payment-number-input-block">
                        <p className="monthly-payment-number-input-title">
                          ROUTING NUMBER
                        </p>
                        <input
                          type="text"
                          className="monthly-payment-number-input"
                          name="routingNumber"
                          value={patientInfo?.routingNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="monthly-payment-number-input-block">
                        <p className="monthly-payment-number-input-title">
                          ACCOUNT NUMBER
                        </p>
                        <input
                          type="text"
                          className="monthly-payment-number-input"
                          name="accountNumber"
                          value={patientInfo?.accountNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="monthly-payment-number-input-block">
                        <p className="monthly-payment-number-input-title">
                          CARD NUMBER
                        </p>
                        <input
                          type="text"
                          className="monthly-payment-number-input"
                          name="cardNumber"
                          value={patientInfo?.cardNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="monthly-payment-number-input-block">
                        <p className="monthly-payment-number-input-title">
                          EXPIRATION DATE
                        </p>
                        <input
                          type="text"
                          className="monthly-payment-number-input"
                          name="expDate"
                          value={patientInfo?.expirationDate}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="monthly-payment-number-input-block">
                        <p className="monthly-payment-number-input-title">
                          CVV CODE
                        </p>
                        <input
                          type="text"
                          className="monthly-payment-number-input"
                          name="cvvCode"
                          value={patientInfo?.cvvCode}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="monthly-payment-right">
                <div className="monthly-payment-number-input-block">
                  <p className="monthly-payment-number-input-title">Date</p>
                  <input
                    type="date"
                    className="monthly-payment-number-input"
                    name="orthoDate"
                    value={
                      patientInfo?.orthoDate
                        ? patientInfo?.orthoDate?.split('T')[0]
                        : patientInfo?.orthoDate
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="monthly-payment-number-input-block">
                  <p className="monthly-payment-number-input-title">Sign</p>
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{
                      width: 375,
                      height: 120,
                      className: 'sigCanvas',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="monthly-payment-button-wrapper">
            <button type="submit" className="monthly-payment-next">
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* <PDFViewer showToolbar width={'100%'} height={2500}>
				<MonthlyPaymentDownload styles={monthlyPaymentDownloadStyles} />
			</PDFViewer> */}
    </>
  );
};

export default MonthlyPaymentForm;
